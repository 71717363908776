@font-face {font-family: "rxicon";
  src: url('iconfont.eot?t=1601000350232'); /* IE9 */
  src: url('iconfont.eot?t=1601000350232#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALIAAsAAAAABmwAAAJ8AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCfgpwgQwBNgIkAwwLCAAEIAWEVQc6G7YFEdWTOWRfFdiN5R9pfRGE65y3wYqkhv6mWofXTpDnQTxQc71vk70UERSBQiCHtuMr1NclhgOHHPnFY4lyy1sB0IczfMz32/akbyUtJCzyOvRv/WQ7Q8ewHDqQO7imftrbAx+Af/TWHt044YEMrBtoRFGAE/QNYxe4hPMEFFVTc6H/wEGjFBZZ0wLC3FkzJimc84giixcWZDVri74PB+UUTnYlx4Hw8eGNtqEwiVzKmjts+oCper/o+aJXujEfY32ElwBuziFFc0TCmNrIMKnA6FTRSFSffQ0LvOiVz3vR0//HE0QkAiFj5RYHjZGW3JHg8qm+FxTvODsFWX22iujIosr7OgjFiq1Zc+3Z6D8d5ce/k75JvUUFHy1Wrhwv24zPePsd6u2oKm4hrqztRTdyNWvEG4hBTHzYlonO9CozZaWJIL+/ZOni8HdbS0onIPAHyyeU6v6tcLEI7hyRk0Dsl01hCb2FH9VQcjv5fElcslLTiJIDXMP289pSxXpMTVBUUeRhR1n3x5sq3FAiyFQY4Sik2s3ZMnVTNJKNIaeYlmpmG7pAKKqZXpuLKWe2VIhF0NQ6AEFxpyBR0mlIFVeARvIc5FR0CzLFfYCixvizZzENw/CiIEYlaEFfEMw0kuOW16eS/Ua39Yq54Af/yGuAXqjyshDvcEQeu2DdXS1CQDwN0E5yGfb9BDNPEY3kXmRuioLqrpSbacheMCpBC/qCYKaR/EJ5+dRuf6PbesW1LWnlH3kNsDdUedmKdrqxR9u1F6+7q0UICGROA7QcFbWHkQnm+lERjeR+RGhuCrsT9dXl6xqGb9oFRVnr05CENERNEOW0q2+hNcsAAA==') format('woff2'),
  url('iconfont.woff?t=1601000350232') format('woff'),
  url('iconfont.ttf?t=1601000350232') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1601000350232#rxicon') format('svg'); /* iOS 4.1- */
}

.rxicon {
  font-family: "rxicon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rxcm_icon_tagbg2:before {
  content: "\e73f";
}

.rxcm_icon_tagbg:before {
  content: "\e73e";
}

