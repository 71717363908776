.rx_loading {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 25px;
    height: calc(100% - 25px);
    background-color: rgba(255, 255, 255, .9);
    z-index: 10000;
    padding-top: 50px;
}

.rx_loading span {
    display: inline-block;
    vertical-align: middle;
    width: .6em;
    height: .6em;
    margin: .19em;
    background: #007DB6;
    border-radius: .6em;
    -webkit-animation: rx_loading 1s infinite alternate;
    animation: rx_loading 1s infinite alternate;
}

.rx_loading span:nth-of-type(2) {
    background: #008FB2;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.rx_loading span:nth-of-type(3) {
    background: #009B9E;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.rx_loading span:nth-of-type(4) {
    background: #00A77D;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.rx_loading span:nth-of-type(5) {
    background: #00B247;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.rx_loading span:nth-of-type(6) {
    background: #5AB027;
    -webkit-animation-delay: 1.0s;
    animation-delay: 1.0s;
}

.rx_loading span:nth-of-type(7) {
    background: #A0B61E;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

@-webkit-keyframes rx_loading {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rx_loading {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.app_loading {
    position: fixed;
    width: 100%;
    text-align: center;
    top: 30px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, .95);
    z-index: 10000;
    padding-top: 280px;
    color: #999999;
    font-size: 24px;
}

.rx_loading2 {
    width: 150px;
    height: 15px;
    margin: 0 auto;
    margin-top: 16px;
}

.rx_loading2 span {
    display: inline-block;
    width: 15px;
    height: 100%;
    margin-right: 5px;
    border-radius: 50%;
    background: #0095FF;
    animation: load 1.04s ease infinite;
    -webkit-animation: load 1.04s ease infinite;
}

.rx_loading2 span:last-child {
    margin-right: 0px;
}

@keyframes load {
    0% {
        opacity: 1;
        -webkit-transform: scale(1.3);
    }

    100% {
        opacity: 0.2;
        -webkit-transform: scale(.3);
    }
}

@-webkit-keyframes load {
    0% {
        opacity: 1;
        -webkit-transform: scale(1.3);
    }

    100% {
        opacity: 0.2;
        -webkit-transform: scale(.3);
    }
}

.rx_loading2 span:nth-child(1) {
    animation-delay: 0.13s;
    -webkit-animation-delay: 0.13s;
}

.rx_loading2 span:nth-child(2) {
    animation-delay: 0.26s;
    -webkit-animation-delay: 0.26s;
}

.rx_loading2 span:nth-child(3) {
    animation-delay: 0.39s;
    -webkit-animation-delay: 0.39s;
}

.rx_loading2 span:nth-child(4) {
    animation-delay: 0.52s;
    -webkit-animation-delay: 0.52s;
}

.rx_loading2 span:nth-child(5) {
    animation-delay: 0.65s;
    -webkit-animation-delay: 0.65s;
}