@import './theme/index.css';
@import './icon/iconfont.css';
@import './loading.css';

[class^="rxcm_icon_"]:before {
    font-family: "rxicon" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-size: 14px;
    line-height: 1.6;
}

a {
    color: #207ee9;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
    color: #019aff;
}

a img {
    border: 0;
}

a[title='站长统计'] {
    position: fixed;
    width: 1px;
    height: 1px;
    overflow: hidden;
    bottom: 0;
    left: 0;
    display: block;
    line-height: 0;
    opacity: 0;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: #999999;
}

.hover {
    color: #111111 !important;
}

.hover:hover {
    color: #4977FF !important;
}

.hover2 {
    color: #2659ED !important;
}

.hover2:hover {
    color: #4977FF !important;
}

.hoverMoveUp {
    transition: transform .6s;
}

.hoverMoveUp:hover {
    transform: translateY(-10px);
}

.hoverBg:hover {
    background-color: #FBFBFB !important;
}

/* .hoverBg:hover .hover2{
	color: #4977FF !important;
}
.hoverBg:hover	.hover{
	color: #4977FF !important;
} */

.hoverShadow:hover {
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-input-placeholder {
    color: #999999;
}

.vx-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.vx-flex-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.vx-flex_item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
}

#appTop {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    transition: height .6s;
    transition-property: height, box-shadow;
}

#appTop.border {
    box-shadow: 0px 0px 6px rgba(66, 66, 66, 0.1);
    height: 113px;
}


#appTop .hello a {
    color: #666;
}

#appTop .hello a:hover {
    color: rgb(3, 72, 163);
}

#topNav {
    height: 100px;
    max-width: 1200px;
    margin: auto;
}

#topNav .navRight {
    text-align: right;
}

#topNav .navLeft .cname {
    display: inline-block;
    vertical-align: middle;
    color: #2659ED;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
    padding: 0 15px;
    margin-left: 15px;
    border-left: 1px rgba(182, 182, 182, 0.6) solid;
}

#appMain {
    color: #121212;
    padding-top: 135px;
    box-sizing: border-box;
}

.logo {
    display: inline-block;
    width: 200px;
    height: 58px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("./logo.png");
    vertical-align: middle;
}

.cnName {
    display: inline-block;
    font-size: 24px;
    vertical-align: middle;
}

.h01,
h1 {
    font-size: 30px;
    font-weight: bold;
    color: #111111;
}

.h02,
h2 {
    font-size: 24px;
    font-weight: bold;
    color: #111111;
}

.h03,
h3 {
    font-size: 18px;
    color: #111111;
    font-weight: bold;
}

.h04,
h4 {
    font-size: 14px;
    color: #111111;
    font-weight: bold;
}

.h05,
h5 {
    font-size: 12px;
    color: #111111;
    font-weight: bold;
}

.el-button {
    border-radius: 0 !important;
}

.btn01 {
    font-size: 16px;
    padding: 12px 32px;
    box-shadow: 0 10px 20px 0 rgb(38, 89, 237, 0.2);
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.c1 {
    -webkit-line-clamp: 1;
}

.c2 {
    -webkit-line-clamp: 2;
}

.c3 {
    -webkit-line-clamp: 3;
}

.tag {
    display: inline-block;
    padding: 0 10px;
    line-height: 1.8;
    background: #EDF1F8;
    border: 1px solid #1D44A6;
    color: #1D44A6;
    text-align: center;
    font-size: 14px;
}

.tag.org {
    background: #FEF7ED;
    border: 1px solid #FF9042;
    color: #FF9042;
}

.tag.yelo {
    background: #FEF7ED;
    border: 1px solid #FFB027;
    color: #FFB027;
}

.cmenu {
    position: relative;
    display: inline-block;
    padding: 10px 0;
    line-height: 1;
    color: #666666;
    font-size: 18px;
    vertical-align: middle;
    margin: 0 30px;
    cursor: pointer;
}

.cmenu:hover,
.cmenu.act {
    color: #2659ED;
    font-weight: bold;
    border-bottom: 2px #2659ED solid;
}

.el-tooltip__popper.is-light.mytip {
    border: 1px #485bb6 solid;
    border-radius: 3px;
    padding: 5px;
    color: #485bb6;
    position: absolute;
    background-color: #eff2ff;
    display: inline-block;
    font-size: 15px;
}

.scrollbar::-webkit-scrollbar {
    width: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #999999;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #eeeeee;
}